import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const propTypes = {
  pageContext: PropTypes.shape({
    url: PropTypes.string.isRequired,
    redirect: PropTypes.string.isRequired,
  }).isRequired,
};

const RedirectPage = ({ pageContext }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `}
      render={(data) => {
        return (
          <Helmet>
            <meta
              httpEquiv="refresh"
              content={`0;url=${data.site.siteMetadata.siteUrl}${pageContext.redirect}`}
            />
          </Helmet>
        );
      }}
    />
  );
};

RedirectPage.propTypes = propTypes;
export default RedirectPage;
